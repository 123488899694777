<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],
            loading: false,
            userinfo: [],
            micoapplist: [],
        };
    },
    mounted() {
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.title = this.$t("menuitems.icp.micoapp.info");
        this.items = [
            {
                text: this.$t("menuitems.micoapp.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.icp.micoapp.list"),
                href: "/micoapp",
            },
            {
                text: this.$t("menuitems.icp.micoapp.info"),
                active: true,
            },
        ];
        this.getmicoappinit();
    },
    methods: {
        getmicoappinit() {
            var that = this;
            that.loading = true;
            that.$axios
                .post(
                    "https://admin.aicoiot.beer/api.json",
                    {
                        action: "getmicoappinit",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    if (response.data.code == 0) {
                        that.micoappinfo = response.data.data;
                    } else {
                        that.$message.error(response.data.msg);
                    }
                })
                .catch(function (error) {
                    that.loading = false;
                    that.$message.error(error);
                });
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">

                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>